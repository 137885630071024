import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Row, Col, Button, Badge } from 'react-bootstrap'
import { ReactComponent as Logo } from './logo.svg'
import MenuModal from '../menuModal/menuModal'

import { userLogoutAsync } from '../../../core/actions/authActions'

import './header.scss'

const Header = (props) => {
    const authObj = useSelector((state) => state.authReducer)
    const dispatch = useDispatch()
    const [menuShow, setMenuShow] = useState(false)

    const { isAuthenticated, user } = authObj

    // let currentPath = useLocation().pathname

    // handle click event of the logout button
    const handleLogout = () => {
        dispatch(userLogoutAsync())
    }

    const getInitialFirstName = (firstName) => {
        return firstName && firstName.substring(0, 1).toUpperCase()
    }

    return (
        <>
            <MenuModal userData={user} menuShow={menuShow} setMenuShow={setMenuShow} handleLogout={handleLogout} />
            <Container className="header">
                <Row>
                    <Col>
                        <Logo />
                    </Col>
                    <Col>
                        {isAuthenticated ? (
                            <Badge
                                onClick={(e) => setMenuShow(true)}
                                pill
                                variant="primary"
                                style={{ cursor: 'pointer', fontSize: 18, float: 'right', marginRight: 30, backgroundColor: '#000', color: '#fff' }}>
                                {getInitialFirstName(user.firstName)}
                            </Badge>
                        ) : (
                            <Button variant="primary" style={{ cursor: 'pointer', float: 'right', marginRight: 0 }}>
                                <span onClick={(e) => props.setSignUpShow(true)}>Sign Up</span> / <span onClick={(e) => props.setSignInShow(true)}>Log In</span>
                            </Button>
                        )}
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Header
