import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Container, Jumbotron } from 'react-bootstrap'

import './noMatch.scss'

const NoMatch = (props) => {
    return (
        <React.Fragment>
            <Jumbotron fluid className="mt-50">
                <Container>
                    <h1>404</h1>
                    <p>Sorry, the page you visited does not exist.</p>
                    <Link to="/">
                        <Button type="primary">Back Home</Button>
                    </Link>
                </Container>
            </Jumbotron>
        </React.Fragment>
    )
}

export default NoMatch
