import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { Link, Redirect, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap'
import Wrapper from '../../shared/wrapper/wrapper'
// import Header from '../../shared/header/header'
import Footer from '../../shared/footer/footer'
import { submitUpdatePasswordForm } from '../../../core/actions/forgotPasswordActions'
import { ReactComponent as Logo } from '../../shared/header/logo.svg'

import './resetPassword.scss'

function ResetPassword(props) {
    let location = useLocation()
    const [validLink, setValidLink] = useState(false)
    const [userId, setUserId] = useState(null)
    const [hash, setHash] = useState(null)
    const [formSuccess, setFormSuccess] = useState(false)
    const [message, setMessage] = useState('')
    const { register, handleSubmit, reset, errors, watch } = useForm()
    const password = useRef({})
    password.current = watch('password', '')

    useEffect(() => {
        const validateResetLink = async () => {
            const url = location.pathname.split('/')

            const hash = url[2]
            const user_id = url[3]

            setUserId(user_id)
            setHash(hash)

            const response = await axios.post('/api/forgotpassword/validate', {
                hash: hash,
                user_id: user_id,
            })

            if (response.status === 200) {
                const { valid, message } = response.data
                if (valid === true) {
                    setValidLink(true)
                    setMessage('')
                } else {
                    setValidLink(false)
                    setMessage(message)
                }
            }
        }
        validateResetLink()
    }, [location])

    const authObj = useSelector((state) => state.authReducer)
    const { isAuthenticated } = authObj

    const onSubmit = async (userData) => {
        userData['user_id'] = userId
        userData['hash'] = hash

        const result = await submitUpdatePasswordForm(userData)
        if (result?.data?.success === true) {
            reset()
            setFormSuccess(true)
            setMessage('Your password has been changed! Please login with your new password.')
        } else {
            setFormSuccess(false)
            setMessage('Sorry, an unknown error occured. Please try again later.')
        }
    }

    return (
        <Wrapper>
            {/* <Header /> */}
            <Container className="header">
                <Row>
                    <Col>
                        <Logo />
                    </Col>
                </Row>
            </Container>
            <div className="resetpassword">
                <Container>
                    <Row className="justify-content-md-center">
                        <Col sm={10} className="contentBox paddingBox">
                            <div className="contentBoxHeader">
                                <h3>Reset your password</h3>
                            </div>
                            <div className="contentBoxBody">
                                {message !== '' && <Alert variant="warning">{message}</Alert>}

                                {!formSuccess && validLink === true ? (
                                    <Form onSubmit={handleSubmit(onSubmit)}>
                                        <Form.Row>
                                            <Col sm={7}>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Form.Label>New Password</Form.Label>
                                                    <Form.Control
                                                        autoFocus
                                                        type="password"
                                                        name="password"
                                                        ref={register({
                                                            required: true,
                                                            maxLength: 30,
                                                            minLength: 6,
                                                        })}
                                                    />
                                                    {errors.password?.type === 'required' && <Form.Text className="text-error">This field is required</Form.Text>}
                                                    {errors.password?.type === 'minLength' && <Form.Text className="text-error">Minimum length is 6 characters</Form.Text>}
                                                    {errors.password?.type === 'maxLength' && <Form.Text className="text-error">Maximum length is 30 characters</Form.Text>}
                                                </Form.Group>

                                                <Form.Group controlId="formBasicPassword2">
                                                    <Form.Label>Confirm Password</Form.Label>
                                                    <Form.Control
                                                        type="password"
                                                        name="password2"
                                                        ref={register({
                                                            validate: (value) => value === password.current || 'The passwords do not match',
                                                        })}
                                                    />
                                                    {errors.password2 && <Form.Text className="text-error">{errors.password2.message}</Form.Text>}
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>
                                        <Form.Row>
                                            <Col>
                                                <div className="mt-50">
                                                    <Button className="primaryButton" size="lg" variant="primary" type="submit">
                                                        Update Password
                                                    </Button>
                                                    <span className="signupText">
                                                        Back to <Link to="/">homepage</Link>
                                                    </span>
                                                </div>
                                            </Col>
                                        </Form.Row>
                                    </Form>
                                ) : (
                                    <span className="">
                                        Back to <Link to="/">homepage</Link> page
                                    </span>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </Wrapper>
    )
}

export default ResetPassword
