import axios from 'axios'

// const API_URL = 'http://localhost:4000'

// get list of the users
export const setAudioTimestamp = async (userData) => {
    try {
        return await axios.post(`/api/users/setAudioTimestamp`, userData)
    } catch (err) {
        return {
            error: true,
            response: err.response,
        }
    }
}

// get list of the users
export const getAudioTimestamp = async () => {
    try {
        return await axios.get(`/api/users/getAudioTimestamp`)
    } catch (err) {
        return {
            error: true,
            response: err.response,
        }
    }
}

// get audio
export const getAudio = async (audioData) => {
    try {
        return await axios.post(`/api/content/getAudio`, audioData)
    } catch (err) {
        return {
            error: true,
            response: err.response,
        }
    }
}

// get data for current process
export const getProcessByPosition = async (positionData) => {
    try {
        return await axios.post(`/api/content/getProcessByPosition`, positionData)
    } catch (err) {
        return {
            error: true,
            response: err.response,
        }
    }
}
