import React, { useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap'
import Wrapper from '../../shared/wrapper/wrapper'
import Header from '../../shared/header/header'
import Footer from '../../shared/footer/footer'

import { submitForgotPasswordForm } from '../../../core/actions/forgotPasswordActions'

import './forgotPassword.scss'

function ForgotPassword(props) {
    const [message, setMessage] = useState('')
    const [emailDisabled, setEmailDisabled] = useState(false)
    const [hideElement, setHideElement] = useState(false)
    const authObj = useSelector((state) => state.authReducer)

    const { isAuthenticated } = authObj

    const { register, handleSubmit, reset, errors } = useForm()

    const onSubmit = async (userData) => {
        setMessage('')
        const result = await submitForgotPasswordForm(userData)

        if (result.data.success === true) {
            reset()
            setEmailDisabled(true)
            setHideElement(true)
            setMessage('An email with password reset instructions has been sent to your email address, if it exists on our system.')
        } else {
            setMessage('Unexpected Error!', 'Sorry, Something went wrong. Please try again later.')
            console.log('Error', result.data.message)
        }
    }

    // if (isAuthenticated) {
    //     return <Redirect to={{ pathname: '/dashboard' }} />
    // }

    return (
        <Wrapper>
            <Header />
            <div className="sign-in">
                <Container>
                    <Row className="justify-content-md-center">
                        <Col sm={10} className="contentBox paddingBox">
                            <div className="contentBoxHeader">
                                <h3>Reset your password</h3>
                            </div>
                            <div className="contentBoxBody">
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <Form.Row>
                                        <Col sm={7}>
                                            {message !== '' && <Alert variant="warning">{message}</Alert>}
                                            <Form.Group controlId="forgotPasswordEmail" className={hideElement && 'displayNone'}>
                                                <Form.Label>Please enter the email address associated with your account. We will send you a link to reset your password.</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    name="emailAddress"
                                                    size="sm"
                                                    placeholder="example@email.com"
                                                    maxLength={30}
                                                    ref={register({
                                                        required: true,
                                                        pattern: /^\S+@\S+$/i,
                                                    })}
                                                    autoFocus
                                                    disabled={emailDisabled}
                                                />
                                                {errors.emailAddress?.type === 'required' && <Form.Text className="text-error">This field is required</Form.Text>}
                                                {errors.emailAddress?.type === 'pattern' && <Form.Text className="text-error">Invalid email</Form.Text>}
                                            </Form.Group>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row>
                                        <Col>
                                            <div className="mt-50">
                                                <span className={hideElement ? 'displayNone' : ''}>
                                                    <Button className="primaryButton" size="sm" variant="primary" type="submit">
                                                        Send
                                                    </Button>
                                                </span>
                                                <span className={hideElement ? '' : 'signupText'}>
                                                    Back to <Link to="/signin">Sign In</Link>
                                                </span>
                                            </div>
                                        </Col>
                                    </Form.Row>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </Wrapper>
    )
}

export default ForgotPassword
