import axios from 'axios'

// const API_URL = 'http://localhost:4000'

export const submitForgotPasswordForm = async (userData) => {
    try {
        return await axios.post(`/api/forgotpassword/form`, userData)
    } catch (err) {
        return {
            error: true,
            response: err.response,
        }
    }
}

export const submitUpdatePasswordForm = async (userData) => {
    try {
        return await axios.post(`/api/forgotpassword/updatepassword`, userData)
    } catch (err) {
        return {
            error: true,
            response: err.response,
        }
    }
}
