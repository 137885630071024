import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Button, Modal, ListGroup } from 'react-bootstrap'
import { ReactComponent as Icon1 } from './images/icon1.svg'
import { ReactComponent as Icon2 } from './images/icon2.svg'
import { ReactComponent as Icon3 } from './images/icon3.svg'
import { ReactComponent as Icon4 } from './images/icon4.svg'
import { ReactComponent as Icon5 } from './images/icon5.svg'
import { ReactComponent as Icon6 } from './images/icon6.svg'
import { ReactComponent as Icon7 } from './images/icon7.svg'
import './menuModal.scss'
export default function MenuModal(props) {
    const capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    return (
        <>
            {/* <Button onClick={() => setMenuShow(true)}>Menu Modal</Button> */}
            <Modal className="menu-modal" size="sm" show={props.menuShow} onHide={() => props.setMenuShow(false)} aria-labelledby="menu-modal">
                <Modal.Header>
                    <Modal.Title id="title-modal">
                        <ListGroup variant="flush">
                            <ListGroup.Item>
                                <Icon1 className="menu-icon" />
                                {/* {capitalize(props.userData.firstName) + ' ' + capitalize(props.userData.lastName)} */}
                                {props.userData && capitalize(props.userData.firstName) + ' ' + capitalize(props.userData.lastName)}
                            </ListGroup.Item>
                        </ListGroup>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <ListGroup variant="flush">
                                {/* <ListGroup.Item>
                                    <Icon2 className="menu-icon" />
                                    <Link to="">Profile Details</Link>
                                </ListGroup.Item> */}
                                <ListGroup.Item>
                                    <Icon3 className="menu-icon" />
                                    <Link to="/dashboard/change-password">Change Password</Link>
                                </ListGroup.Item>
                                {/* <ListGroup.Item>
                                    <Icon4 className="menu-icon" />
                                    <Link to="">Address Book</Link>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <Icon5 className="menu-icon" />
                                    <Link to="">Payment Methods</Link>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <Icon6 className="menu-icon" />
                                    <Link to="">Contact Preferences</Link>
                                </ListGroup.Item> */}
                                {/* <ListGroup.Item>
                                    <Icon7 className="menu-icon" />
                                    <Link to="">Help &amp; Support</Link>
                                </ListGroup.Item> */}
                            </ListGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button onClick={props.handleLogout} className="menu-logout-btn">
                                Log Out
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}
