import React, { useEffect, useRef } from 'react'
import { Link, useHistory, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Row, Col, Button, Modal, Form } from 'react-bootstrap'
import Swal from 'sweetalert2'

import { userLogoutAsync } from '../../../core/actions/authActions'
import { userSignupService } from '../../../core/actions/userActions'

import './signUpModal.scss'
export default function SignUpModal(props) {
    let history = useHistory()
    const authObj = useSelector((state) => state.authReducer)
    const dispatch = useDispatch()

    const queryParameters = new URLSearchParams(window.location.search)
    const p_from = queryParameters.get('from')

    const { isAuthenticated } = authObj

    const { register, handleSubmit, errors, watch } = useForm()
    const password = useRef({})
    password.current = watch('password', '')

    const onSubmit = async (data) => {
        data['email'] = data.email.toLowerCase()
        data['comingFrom'] = p_from
        const response = await userSignupService(data)

        if (response.data && response.data.success === true) {
            props.setSignUpShow(false)
            Swal.fire({
                icon: 'success',
                title: 'Registration successful',
                text: 'Thank you for registering, you may now log in.',
                confirmButtonText: 'Login',
            }).then((result) => {
                dispatch(userLogoutAsync())
                history.push('/?from=' + p_from)
                if (result.isConfirmed) {
                    props.setSignInShow(true)
                    props.setSignUpShow(false)
                }
            })
        } else if (response.error && response.response && response.response.data && response.response.data.message === 'Email already has an account!') {
            Swal.fire('Oops!', 'An account with this email already exists. Please try logging in or use another email', 'warning')
        } else {
            Swal.fire('Unexpected Error!', 'Sorry, Something went wrong. Please try again later.', 'error')
        }
    }

    const handleKeyPress = (target) => {
        if (target.charCode == 13) {
            // onSubmit()
            handleSubmit(onSubmit)
        }
    }

    // first name field focus
    const firstNameRef = useRef(null)
    useEffect(() => {
        if (firstNameRef.current && document.activeElement.name !== 'password' && document.activeElement.name !== 'password2' && document.activeElement.name !== 'submit') {
            firstNameRef.current.focus()
        }
    })

    return (
        <>
            {/* <Button onClick={() => setSmShow(true)}>Signup modal</Button> */}
            <Modal className="signup-modal" size="md" show={props.signUpShow} onHide={() => props.setSignUpShow(false)} aria-labelledby="signup-modal">
                <Modal.Header closeButton>
                    <Modal.Title id="signup-modal">The Processes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={{ span: 10, offset: 1 }}>
                            <h3>Create an Account</h3>
                        </Col>
                    </Row>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group as={Row} controlId="formGroupFirstName">
                            <Col sm={{ span: 10, offset: 1 }}>
                                <Form.Label>First name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="firstName"
                                    ref={(ref) => {
                                        if (ref) {
                                            register(ref, { required: true, maxLength: 20, pattern: /^[A-Za-z]+$/i })
                                            firstNameRef.current = ref
                                        }
                                    }}
                                />
                                {errors.firstName?.type === 'required' && <Form.Text className="text-error">This field is required</Form.Text>}
                                {errors.firstName?.type === 'maxLength' && <Form.Text className="text-error">Maximum length is 20 characters</Form.Text>}
                                {errors.firstName?.type === 'pattern' && <Form.Text className="text-error">Invalid characters</Form.Text>}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formGroupLastName">
                            <Col sm={{ span: 10, offset: 1 }}>
                                <Form.Label>Last name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="lastName"
                                    ref={register({
                                        required: true,
                                        maxLength: 20,
                                        pattern: /^[A-Za-z]+$/i,
                                    })}
                                />
                                {errors.lastName?.type === 'required' && <Form.Text className="text-error">This field is required</Form.Text>}
                                {errors.lastName?.type === 'maxLength' && <Form.Text className="text-error">Maximum length is 20 characters</Form.Text>}
                                {errors.lastName?.type === 'pattern' && <Form.Text className="text-error">Invalid characters</Form.Text>}
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formGroupGender">
                            <Col sm={{ span: 10, offset: 1 }}>
                                <Form.Label>Gender</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="gender"
                                    ref={register({
                                        required: true,
                                    })}>
                                    <option value=""></option>
                                    <option value="M">Male</option>
                                    <option value="F">Female</option>
                                </Form.Control>
                                <Form.Text muted>Gender is required because some processes are gender specific.</Form.Text>
                                {errors.gender?.type === 'required' && <Form.Text className="text-error">This field is required</Form.Text>}
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formGroupEmail">
                            <Col sm={{ span: 10, offset: 1 }}>
                                <Form.Label>Email address</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    ref={register({
                                        required: true,
                                        pattern: /\S+@\S+\.\S+/,
                                    })}
                                />
                                {errors.email?.type === 'required' && <Form.Text className="text-error">This field is required</Form.Text>}
                                {errors.email?.type === 'pattern' && <Form.Text className="text-error">Invalid email</Form.Text>}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formGroupPassword">
                            <Col sm={{ span: 10, offset: 1 }}>
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="password"
                                    ref={register({
                                        required: true,
                                        maxLength: 30,
                                        minLength: 6,
                                    })}
                                />
                                {errors.password?.type === 'required' && <Form.Text className="text-error">This field is required</Form.Text>}
                                {errors.password?.type === 'minLength' && <Form.Text className="text-error">Minimum length is 6 characters</Form.Text>}
                                {errors.password?.type === 'maxLength' && <Form.Text className="text-error">Maximum length is 30 characters</Form.Text>}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formGroupPassword2">
                            <Col sm={{ span: 10, offset: 1 }}>
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="password2"
                                    ref={register({
                                        required: true,
                                        validate: (value) => value === password.current || 'The passwords do not match',
                                    })}
                                    // onKeyPress={handleKeyPress}
                                />
                                {errors.password2?.type === 'required' && <Form.Text className="text-error">This field is required</Form.Text>}
                                {errors.password2 && <Form.Text className="text-error">{errors.password2.message}</Form.Text>}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formGroupRefer">
                            <Col sm={{ span: 10, offset: 1 }}>
                                {/* <Form.Label>How did you hear about us?</Form.Label> */}
                                <Form.Label>Name of person who referred you?</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="refer"
                                    ref={register({
                                        required: false,
                                        maxLength: 256,
                                    })}
                                    onKeyPress={handleKeyPress}
                                />
                                {/* {errors.password2 && <Form.Text className="text-error">{errors.password2.message}</Form.Text>} */}
                            </Col>
                        </Form.Group>

                        <Row className="modal-footer">
                            <Col sm={{ span: 10, offset: 2 }}>
                                <p className="disclaimer-text">
                                    By Signing up, you agree to our{' '}
                                    <a href="https://www.termsfeed.com/live/1fcd5440-b5e8-435c-bb7b-acb9cc023903" rel="noopener noreferrer" target="_blank">
                                        <Button variant="link">Terms of Service</Button>
                                    </a>{' '}
                                    and{' '}
                                    <a href="https://www.termsfeed.com/live/bf3c4db7-0712-4f44-8157-fdc8c0bdf55c" rel="noopener noreferrer" target="_blank">
                                        <Button variant="link">Privacy policy</Button>
                                    </a>
                                    .
                                </p>
                                <div className="text-right">
                                    <Button type="submit" name="submit" className="submit-button">
                                        Create
                                    </Button>
                                </div>
                                <div className="text-right returning-customer">
                                    Returning Customer?{' '}
                                    <Button
                                        variant="link"
                                        onClick={() => {
                                            props.setSignInShow(true)
                                            props.setSignUpShow(false)
                                        }}>
                                        <b>Log In</b>
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}
