import axios from 'axios'
import { VERIFY_TOKEN_STARTED, VERIFY_USER_SUCCESS, VERIFY_TOKEN_END, USER_LOGIN_STARTED, USER_LOGIN_FAILURE, USER_LOGOUT } from './types'

axios.defaults.withCredentials = true

//const API_URL = 'http://localhost:4000'

// set token to the axios
export const setAuthToken = (token) => {
    if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    } else {
        delete axios.defaults.headers.common['Authorization']
    }
}

// verify refresh token to generate new access token if refresh token is present
export const verifyTokenService = async () => {
    try {
        return await axios.post(`/api/users/verifyToken`)
    } catch (err) {
        return {
            error: true,
            response: err.response,
        }
    }
}

// user login API to validate the credential
export const userLoginService = async (email, password) => {
    try {
        return await axios.post(`/api/users/signin`, { email, password })
    } catch (err) {
        return {
            error: true,
            response: err.response,
        }
    }
}

// manage user logout
export const userLogoutService = async () => {
    try {
        return await axios.post(`/api/users/logout`)
    } catch (err) {
        return {
            error: true,
            response: err.response,
        }
    }
}

// verify token - start
export const verifyTokenStarted = (silentAuth = false) => {
    return {
        type: VERIFY_TOKEN_STARTED,
        payload: {
            silentAuth,
        },
    }
}

// verify token - end/failure
export const verifyTokenEnd = () => {
    return {
        type: VERIFY_TOKEN_END,
    }
}

// user login - start
export const userLoginStarted = () => {
    return {
        type: USER_LOGIN_STARTED,
    }
}

// user login - failure
export const userLoginFailure = (error = 'Something went wrong. Please try again later.') => {
    return {
        type: USER_LOGIN_FAILURE,
        payload: {
            error,
        },
    }
}

// verify token - success
export const verifyUserSuccess = ({ token, expiredAt, user }) => {
    return {
        type: VERIFY_USER_SUCCESS,
        payload: {
            token,
            expiredAt,
            user,
        },
    }
}

// handle user logout
export const userLogout = () => {
    setAuthToken()
    return {
        type: USER_LOGOUT,
    }
}

// handle verify token
export const verifyTokenAsync =
    (silentAuth = false) =>
    async (dispatch) => {
        dispatch(verifyTokenStarted(silentAuth))

        const result = await verifyTokenService()

        if (result.error) {
            dispatch(verifyTokenEnd())
            if ([401, 403].includes(result.response.status)) dispatch(userLogout())
            return
        }

        if (result.status === 204) dispatch(verifyTokenEnd())
        else dispatch(verifyUserSuccess(result.data))
    }

// handle user login
export const userLoginAsync = (email, password) => async (dispatch) => {
    dispatch(userLoginStarted())

    const result = await userLoginService(email, password)

    if (result.error) {
        dispatch(userLoginFailure(result.response.data.message))
        return
    }

    dispatch(verifyUserSuccess(result.data))
}

// handle user logout
export const userLogoutAsync = () => (dispatch) => {
    dispatch(userLogout())
    userLogoutService()
}
