import React, { useState } from 'react'
import { Row, Col, Button, Modal, Form } from 'react-bootstrap'

import './checkpointModal.scss'
export default function checkpointModal(props) {
    return (
        <>
            <Modal className="checkpoint-modal" size="md" show={props.checkpointShow} onHide={() => props.setCheckpointShow(false)} aria-labelledby="signup-modal" backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title id="title-modal">
                        <h3>Welcome back!</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col className="text-center" sm={{ span: 10, offset: 1 }}>
                            <p>Looks like you may not have completed this section. Press continue to resume where you left off.</p>
                        </Col>
                    </Row>
                    {/* Sheila requested we no longer allow users to start over.
                        <Row>
                        <Col className="text-center">
                            <Button
                                variant="light"
                                className="large-action-btn"
                                onClick={() => {
                                    props.setCheckpointShow(false)
                                    props.reset()
                                    const userData = {}
                                    userData.lastPlayed = 0
                                    props.setAudioTimestamp(userData)
                                }}>
                                Start Over
                            </Button>
                        </Col>
                    </Row> */}
                    <Row>
                        <Col className="text-center">
                            <Button
                                variant="primary"
                                className="large-action-btn"
                                onClick={() => {
                                    props.setCheckpointShow(false)
                                    props.resume()
                                }}>
                                Continue
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}
