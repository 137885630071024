import axios from 'axios'

// const API_URL = 'http://localhost:4000'

// get list of the users
// export const startStripeSession = async (userData) => {
//     try {
//         return await axios.post(`/api/checkout/createsession`, userData)
//     } catch (err) {
//         return {
//             error: true,
//             response: err.response,
//         }
//     }
// }

// // get list of the users
// export const getStripeSession = async (sessionID) => {
//     try {
//         return await axios.post(`/api/checkout/getsession`, { sessionID })
//     } catch (err) {
//         return {
//             error: true,
//             response: err.response,
//         }
//     }
// }

// get list of the users
export const processPaypalPayment = async (paymentData) => {
    try {
        return await axios.post(`/api/checkout/paypalpayment`, paymentData)
    } catch (err) {
        return {
            error: true,
            response: err.response,
        }
    }
}
