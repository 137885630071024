import React from 'react'

function Wrapper({ children }) {
    return (
        <>
            <div
            // style={{
            //   margin: `0 auto`,
            //   maxWidth: 960,
            //   padding: `0 1.0875rem 1.45rem`,
            // }}
            >
                <div>{children}</div>
            </div>
        </>
    )
}

export default Wrapper
