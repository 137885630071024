import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import './footer.scss'

const Footer = () => (
    <footer>
        <Container>
            <Row>
                <Col>
                    <span className="link">
                        <a href="https://www.termsfeed.com/live/1fcd5440-b5e8-435c-bb7b-acb9cc023903" rel="noopener noreferrer" target="_blank">
                            <b>Terms and Conditions</b>
                        </a>
                    </span>
                    <span className="link">
                        <a href="https://www.termsfeed.com/live/bf3c4db7-0712-4f44-8157-fdc8c0bdf55c" rel="noopener noreferrer" target="_blank">
                            <b>Privacy Policy</b>
                        </a>
                    </span>
                </Col>
                <Col>
                    <div className="footerRight">
                        <div>
                            <a href="https://www.facebook.com/thewellnessfactorinc" rel="noopener noreferrer" target="_blank">
                                <span style={{ fontSize: '2em', color: '#462717', padding: '0 4px' }}>
                                    <i className="fab fa-facebook-square"></i>
                                </span>
                            </a>
                            <a href="https://www.instagram.com/thewellnessfactorinc/" rel="noopener noreferrer" target="_blank">
                                <span style={{ fontSize: '2em', color: '#462717', padding: '0 4px' }}>
                                    <i className="fab fa-instagram-square"></i>
                                </span>
                            </a>
                            {/* <a href="https://twitter.com/TheWellFactInc" rel="noopener noreferrer" target="_blank">
                                <span style={{ fontSize: '2em', color: '#462717', padding: '0 4px' }}>
                                    <i className="fab fa-twitter-square"></i>
                                </span>
                            </a> */}
                            <a href="mailto:sheila@thewellnessfactor.com">
                                <span style={{ fontSize: '2em', color: '#462717', padding: '0 4px' }}>
                                    <i className="fas fa-envelope-square"></i>
                                </span>
                            </a>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </footer>
)

export default Footer
