import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { ReactComponent as BuddhaImage } from './buddha.svg'
import Swal from 'sweetalert2'

import axios from 'axios'

import './hero.scss'

const Hero = (props) => {
    const [contentValue, setContentValue] = useState('')

    useEffect(() => {
        const getContent = async () => {
            const response = await axios.post(`/api/content/getContent`, { contentId: '60eb49f5fc34671eaca9ae6d' })
            if (response && response.data && response.data.success && response.data.value) {
                setContentValue(response.data.value)
            }
        }
        getContent()
    }, [])

    const welcomeMessage = () => {
        Swal.fire({
            title: '<strong>Welcome!</strong>',
            customClass: 'swal-wide',
            html:
                '<p><p>You are about to embark on a journey that has the potential to improve your sense of self-esteem and belonging in this world. In other words, it has the potential to allow you to:</p>' +
                '<b>LIBERATE YOUR LIFE!</b><p></p>' +
                '<p>At minimum, these "PROCESSES" are meditational. However, depending on your "intent" when working with PROCESSES, many have also found them to be able to ease anxiety and help people feel better about themselves from the inside out. The copyrighted title of the PROCESSES is actually:</p> ' +
                '<b>THE SANTI SYSTEM FOR EMOTIONAL REPATTERNING</b><p></p>' +
                '<p>Since none of the processes ever tell you what to do or how to do it, each person experiences their positive change from within themselves based on their own life, past history and intent. The outcomes are always positive but also always unique to each individual. Your own intent for yourself determines your level of benefit.</p>' +
                '<p>The PROCESSES have been proven to work whether you are awake or even sound asleep. Whichever state of conscious awareness that you choose for yourself. PROCESSES tend to work faster the less engaged your conscious mind is however, they do not work better, just faster.</p>' +
                '<p>So now, feel free to relax and enjoy your relaxation and positive, personal growth!</p></p>',
            showCloseButton: true,
            showConfirmButton: false,
        })
    }

    return (
        <section className="hero">
            <Container>
                <Row>
                    <Col className="heroBox" xs={12} sm={12} lg={7}>
                        <div dangerouslySetInnerHTML={{ __html: contentValue }} />
                        <p>
                            If you are comfortable in your understanding already, then please proceed by{' '}
                            <span className="clickable" onClick={(e) => props.setSignUpShow(true)}>
                                <b>Signing Up</b>
                            </span>{' '}
                            or{' '}
                            <span className="clickable" onClick={(e) => props.setSignInShow(true)}>
                                <b>Logging in</b>
                            </span>
                            .
                        </p>
                        <br />
                        <div className="welcomeMessageButtonContainer">
                            <Button variant="primary" onClick={welcomeMessage}>
                                Welcome Message
                            </Button>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} lg={5}>
                        <BuddhaImage className="heroImage" />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Hero
