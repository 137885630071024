import React, { useEffect } from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import Home from './pages/home/home'
import ForgotPassword from './pages/forgotPassword/forgotPassword'
import ResetPassword from './pages/resetPassword/resetPassword'
import Dashboard from './pages/dashboard/dashboard'
import ChangePassword from './pages/dashboard/changePassword/changePassword'
// import Success from './pages/payment/success'
import PrivateRoute from './shared/privateRoute/privateRoute'
import NoMatch from './pages/noMatch/noMatch'

import { verifyTokenAsync } from '../core/actions/authActions'

function App() {
    const authObj = useSelector((state) => state.authReducer)
    const dispatch = useDispatch()

    const { isAuthenticated } = authObj

    // verify token on app load
    useEffect(() => {
        dispatch(verifyTokenAsync())
    }, [dispatch])

    return (
        <div className="App">
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/forgot-password" component={ForgotPassword} />
                    <Route exact path="/reset-password/:hash/:id" component={ResetPassword} />
                    {/* <Route path="/success" render={(props) => <Success {...props} />} /> */}
                    <PrivateRoute exact path="/dashboard" component={Dashboard} isAuthenticated={isAuthenticated} />
                    <PrivateRoute exact path="/dashboard/change-password" component={ChangePassword} isAuthenticated={isAuthenticated} />
                    <Route component={NoMatch} />
                </Switch>
            </BrowserRouter>
        </div>
    )
}

export default App
