import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Container, Row, Col, Button, Badge } from 'react-bootstrap'
import { ReactComponent as Logo } from '../../shared/header/logo.svg'

import Wrapper from '../../shared/wrapper/wrapper'
// import Header from '../../shared/header/header'
import Hero from './hero/hero'
import HowItWork from './howItWork/howItWork'
import Footer from '../../shared/footer/footer'
import SignInModal from '../../shared/signInModal/signInModal'
import SignUpModal from '../../shared/signUpModal/signUpModal'
import ForgotPasswordModal from '../../shared/forgotPasswordModal/forgotPasswordModal'

import './home.scss'

function Home() {
    const [signInShow, setSignInShow] = useState(false)
    const [signUpShow, setSignUpShow] = useState(false)
    const [forgotPasswordShow, setForgotPasswordShow] = useState(false)
    const authObj = useSelector((state) => state.authReducer)

    const { isAuthenticated } = authObj

    if (isAuthenticated) {
        return <Redirect to={{ pathname: '/dashboard' }} />
    }

    return (
        <Wrapper className="home">
            <SignInModal signInShow={signInShow} setSignInShow={setSignInShow} setSignUpShow={setSignUpShow} setForgotPasswordShow={setForgotPasswordShow} />
            <SignUpModal signUpShow={signUpShow} setSignUpShow={setSignUpShow} setSignInShow={setSignInShow} />
            <ForgotPasswordModal forgotPasswordShow={forgotPasswordShow} setForgotPasswordShow={setForgotPasswordShow} setSignInShow={setSignInShow} />
            <Container className="header">
                <Row>
                    <Col>
                        <Logo />
                    </Col>
                    <Col>
                        <Button variant="primary" style={{ cursor: 'pointer', float: 'right', marginRight: 0 }}>
                            <span onClick={(e) => setSignUpShow(true)}>Sign Up</span> / <span onClick={(e) => setSignInShow(true)}>Log In</span>
                        </Button>
                    </Col>
                </Row>
            </Container>
            <Hero setSignInShow={setSignInShow} setSignUpShow={setSignUpShow} />
            <HowItWork setSignInShow={setSignInShow} setSignUpShow={setSignUpShow} />
            <Footer />
        </Wrapper>
    )
}

export default Home
