import React from 'react'
import { Container, Row, Col, CardDeck, Card } from 'react-bootstrap'
import { ReactComponent as Icon1 } from './images/icon1.svg'
import { ReactComponent as Icon2 } from './images/icon2.svg'
import { ReactComponent as Icon3 } from './images/icon3.svg'
import { ReactComponent as Icon4 } from './images/icon4.svg'
import { ReactComponent as Icon5 } from './images/icon5.svg'

import './howItWork.scss'

function HowItWork(props) {
    return (
        <Container className="howItWork">
            <Row>
                <Col>
                    <h1 className="title">How It Works</h1>
                    <CardDeck>
                        <Card>
                            <Card.Body>
                                <Icon1 className="icon" />
                                <Card.Text>A step by step program for emotional healing designed to empower the listener and increase self-esteem. </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Body>
                                <Icon2 className="icon" />
                                <Card.Text>
                                    Each step is followed by a waiting period before unlocking the next step. This allows sufficient time for your psyche to absorb your changes, giving you access to
                                    your own innate wisdom.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Body>
                                <Icon3 className="icon" />
                                <Card.Text>There is no major purchase or commitment required simply pay as you go.</Card.Text>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Body>
                                <Icon4 className="icon" />
                                <Card.Text>Your progress will always be saved so you can return at any time and start where you left off.</Card.Text>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Body>
                                <Icon5 className="icon" />
                                <Card.Text>
                                    Each step grants you access to an audio track that will progressively guide you through the system, you can access them any time and from any device.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </CardDeck>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p className="text-center mt-5">
                        To begin please{' '}
                        <span
                            className="link"
                            onClick={(e) => {
                                props.setSignInShow(true)
                                props.setSignUpShow(false)
                            }}>
                            Log In
                        </span>{' '}
                        or{' '}
                        <span
                            className="link"
                            onClick={(e) => {
                                props.setSignInShow(false)
                                props.setSignUpShow(true)
                            }}>
                            Sign Up
                        </span>{' '}
                        for a new account
                    </p>
                </Col>
            </Row>
        </Container>
    )
}

export default HowItWork
