import React, { useEffect, useState, useRef } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col, Button, Modal, Form, Alert } from 'react-bootstrap'

import { userLoginAsync } from '../../../core/actions/authActions'

import './signInModal.scss'

function SignInModal(props) {
    // const [smShow, setSmShow] = useState(true)
    const authObj = useSelector((state) => state.authReducer)
    const dispatch = useDispatch()

    const { userLoginLoading, loginError, isAuthenticated } = authObj

    const email = useFormInput('')
    const password = useFormInput('')

    // handle button click of login form
    const handleLogin = () => {
        dispatch(userLoginAsync(email.value, password.value))
    }

    const handleKeyPress = (target) => {
        if (target.charCode == 13) {
            handleLogin()
        }
    }

    const useFocus = () => {
        const htmlElRef = useRef(null)
        const setFocus = () => {
            htmlElRef.current && htmlElRef.current.focus()
        }

        return [htmlElRef, setFocus]
    }

    const [inputRef, setInputFocus] = useFocus()

    useEffect(() => {
        if (document.activeElement.name !== 'password') {
            setInputFocus()
        }
    })

    // if (isAuthenticated) {
    //     return <Redirect to={{ pathname: '/dashboard' }} />
    // }

    return (
        <>
            {/* <Button onClick={() => setSmShow(true)}>Log In modal</Button> */}
            <Modal className="signin-modal" size="md" show={props.signInShow} onHide={() => props.setSignInShow(false)} aria-labelledby="signup-modal">
                <Modal.Header closeButton>
                    <Modal.Title id="signup-modal">The Processes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={{ span: 10, offset: 1 }}>
                            <h3>Customer Login</h3>
                        </Col>
                    </Row>
                    {loginError && (
                        <Row>
                            <Col sm={{ span: 10, offset: 1 }}>
                                <Alert variant="warning" style={{ marginBottom: 40 }}>
                                    {loginError}
                                </Alert>
                            </Col>
                        </Row>
                    )}
                    <Form>
                        <Form.Group as={Row} controlId="formGroupEmail">
                            <Col sm={{ span: 10, offset: 1 }}>
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" placeholder="" name="email" autoComplete="off" {...email} ref={inputRef} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formGroupPassword">
                            <Col sm={{ span: 10, offset: 1 }}>
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" placeholder="" name="password" autoComplete="off" {...password} onKeyPress={handleKeyPress} />
                            </Col>
                        </Form.Group>
                        <Row className="modal-footer">
                            <Col sm={{ span: 10, offset: 2 }}>
                                <p className="text-right forgot-password-text">
                                    <Button
                                        variant="link"
                                        onClick={() => {
                                            props.setForgotPasswordShow(true)
                                            props.setSignInShow(false)
                                        }}>
                                        Forgot your password?
                                    </Button>
                                </p>
                                <div className="text-right">
                                    <Button type="button" className="submit-button" onClick={handleLogin} disabled={userLoginLoading}>
                                        {userLoginLoading ? 'Loading...' : 'Log In'}
                                    </Button>
                                </div>
                                <div className="text-right returning-customer">
                                    New Customer?{' '}
                                    <Button
                                        variant="link"
                                        onClick={() => {
                                            props.setSignInShow(false)
                                            props.setSignUpShow(true)
                                        }}>
                                        <b>Sign Up</b>
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

// custom hook to manage the form input
const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue)

    const handleChange = (e) => {
        setValue(e.target.value)
    }
    return {
        value,
        onChange: handleChange,
    }
}

export default SignInModal
