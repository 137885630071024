import React, { useState, useRef, useEffect } from 'react'
import ReactPlayer from 'react-player/lazy'
import { Container, Row, Col } from 'react-bootstrap'
import Duration from '../../../shared/duration/duration'
import Swal from 'sweetalert2'
import moment from 'moment'
import axios from 'axios'

import { updateUserService } from '../../../../core/actions/userActions'
import { getAudioTimestamp, setAudioTimestamp, getAudio } from '../../../../core/actions/audioActions'

import CheckpointModal from '../../../shared/checkpointModal/checkpointModal'

import ImgBackground1 from '../../../../images/step-background-1.png'
import ImgBackground2 from '../../../../images/step-background-2.png'

import './step.scss'

function Step({ userData, setUserData, stepContent, setUpdateContent }) {
    let playerRef = useRef()

    const [url, setUrl] = useState('')
    const [pip, setPip] = useState(false)
    const [playing, setPlaying] = useState(false)
    const [controls] = useState(true)
    const [light] = useState(false)
    const [volume, setVolume] = useState(0.8)
    const [muted] = useState(false)
    const [played, setPlayed] = useState(0)
    const [loaded, setLoaded] = useState(0)
    const [, setLoadedSeconds] = useState(0)
    const [, setPlayedSeconds] = useState(0)
    const [duration, setDuration] = useState(0)
    const [playbackRate] = useState(1.0)
    const [loop] = useState(false)
    const [seeking, setSeeking] = useState(false)
    const [loadedFromDb, setLoadedFromDb] = useState(0)
    const [contentValue, setContentValue] = useState('')
    const [contentValue2, setContentValue2] = useState('')
    const [waitingPeriod, setWaitingPeriod] = useState(7) // default  7
    const [saveCounter, setSaveCounter] = useState(0)
    const [checkpointShow, setCheckpointShow] = useState(false)
    const [imgBackground, setImgBackground] = useState(ImgBackground1)

    const handleCheckpointShow = () => setCheckpointShow(true)

    useEffect(() => {
        userData.process % 2 === 0 ? setImgBackground(ImgBackground1) : setImgBackground(ImgBackground2)
    }, [])

    const handlePlay = () => {
        setPlaying(true)
    }

    const handleEnablePIP = () => {
        setPip(true)
    }

    const handleDisablePIP = () => {
        setPip(false)
    }

    const handlePause = () => {
        onPause()
        let userData = {}
        const lastPlayed = played.toFixed(3)
        userData.lastPlayed = lastPlayed
        setAudioTimestamp(userData)
    }

    const onPause = () => {
        setPlaying(false)
    }

    const reset = () => {
        playerRef.seekTo(0)
    }

    const handleProgress = (state) => {
        // We only want to update time slider if we are not currently seeking
        if (!seeking) {
            //update return progress
            setLoaded(state.loaded)
            setLoadedSeconds(state.loadedSeconds)
            setPlayed(state.played)
            setPlayedSeconds(state.playedSeconds)
        }
    }

    const handleEnded = () => {
        // reset the lastplayed checkpoint
        const userData = {}
        userData.lastPlayed = 0
        setAudioTimestamp(userData)

        nextStep()

        let message = contentValue2.replace('$waitingPeriod%', waitingPeriod)
        message = message.replace('<p>', '').replace('</p>', '')
        // show finished modal
        Swal.fire({
            icon: 'success',
            title: 'Great Job!',
            text: message.textContent || message.innerText || message || '',
        })
        setPlaying(loop)
    }

    const handleDuration = (duration) => {
        setDuration(duration)
    }

    const nextStep = async (buttonClicked = false) => {
        const newUserData = userData.step === 2 ? { ...userData, step: 3 } : buttonClicked ? { ...userData, process: userData.process + 1, step: 1 } : { ...userData }

        const result = await updateUserService(newUserData)
        if (result.error) {
            console.log(result.error)
            return
        }
        setUserData({ ...result.data.user })
        userData.step === 3 && setUpdateContent(true)
    }

    const ref = (player) => {
        playerRef = player
    }

    useEffect(() => {
        if (typeof stepContent._id !== 'undefined' && url === '') {
            getAudio({ audioId: stepContent._id }).then((response) => {
                if (response && response.data && response.data.success) {
                    setUrl(response.data.audioUrl)
                } else {
                    // TODO: set error message
                }
            })
        }
    }, [stepContent, url])

    useEffect(() => {
        getAudioTimestamp().then(async (response) => {
            const lastPlayed = response.data.lastPlayed
            if (lastPlayed > 0) {
                setLoadedFromDb(lastPlayed)
                handleCheckpointShow()
            }
        })
        setLoadedFromDb(true)
    }, [])

    useEffect(() => {
        const getContent = async () => {
            const response = await axios.post(`/api/content/getContent`, { contentId: '6119c533dec0ce1af451cc58' })
            if (response && response.data && response.data.success && response.data.value) {
                setContentValue(response.data.value)
            }
        }
        const getContent2 = async () => {
            const response = await axios.post(`/api/content/getContent`, { contentId: '6122a92ecc9ec12a941fd9cb' })
            if (response && response.data && response.data.success && response.data.value) {
                setContentValue2(response.data.value)
            }
        }
        getContent()
        getContent2()
    }, [])

    useEffect(() => {
        const getWaitingPeriod = async () => {
            const response = await axios.post(`/api/settings/getWaitingPeriod`)
            if (response && response.data && response.data.success) {
                setWaitingPeriod(response.data.value)
            }
        }
        getWaitingPeriod()
    }, [])

    useEffect(() => {
        // save playing time every 5 seconds
        if (playing && saveCounter === 5) {
            const lastPlayed = played.toFixed(3)
            userData.lastPlayed = lastPlayed
            setAudioTimestamp(userData)
            setSaveCounter(0)
        } else {
            const newSaveCounter = saveCounter + 1
            setSaveCounter(newSaveCounter)
        }
    }, [played])

    const resume = () => {
        playerRef.seekTo(loadedFromDb)
    }

    const changeVolume = (event) => {
        setVolume(event.target.value)
    }

    return (
        <Container className="step">
            <Row className="justify-content-md-center">
                <Col sm={10} className="contentBox paddingBox">
                    <div
                        className="contentHeader"
                        style={{ backgroundImage: `url(${imgBackground})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', borderRadius: '10px' }}>
                        <h3 className="mainTitle">{stepContent.title}</h3>
                        <p className="subTitle">{stepContent.desc}</p>
                        <div className="body">
                            {userData.step < 3 && !playing && played === 0 && <h5>Press play to begin</h5>}

                            {userData.step < 3 && (
                                <div className="here">
                                    <div style={{ marginTop: 30, marginBottom: 30 }}>
                                        <span style={{ fontSize: '3em', color: '#000', cursor: 'pointer' }}>
                                            {playing ? <i className="far fa-pause-circle" onClick={handlePause}></i> : <i className="far fa-play-circle" onClick={handlePlay}></i>}
                                        </span>
                                    </div>
                                    <br />
                                    <div style={{ fontSize: '.75em' }}>
                                        <Duration seconds={duration * played} /> / <Duration seconds={duration} />
                                    </div>
                                    <br />
                                    <i className="fas fa-volume-down"></i>
                                    &nbsp;
                                    <input value={volume} type="range" min="0" max="1" step=".1" onChange={changeVolume} />
                                    &nbsp;
                                    <i className="fas fa-volume-up"></i>
                                </div>
                            )}

                            {userData.step === 3 && (
                                <div>
                                    The next process will be available on:
                                    <br />
                                    <br />
                                    {moment(userData.processCompletionTime[userData.process - 1])
                                        .add(waitingPeriod, 'day')
                                        .add(1, 'day')
                                        .format('dddd, MMMM Do YYYY')}
                                    <br />
                                    <br />
                                    <div className="medizate">
                                        <div dangerouslySetInnerHTML={{ __html: contentValue }} />
                                    </div>
                                </div>
                            )}
                        </div>
                        {userData.step < 3 && (
                            <div className="text-center">
                                <ReactPlayer
                                    hidden
                                    ref={ref}
                                    url={url}
                                    width="400px"
                                    height="30px"
                                    className="audioPlayer"
                                    style={{ margin: '0 auto' }}
                                    pip={pip}
                                    playing={playing}
                                    controls={controls}
                                    light={light}
                                    loop={loop}
                                    playbackRate={playbackRate}
                                    volume={parseFloat(volume)}
                                    muted={muted}
                                    onReady={() => console.log('onReady')}
                                    onStart={() => console.log('onStart')}
                                    onPlay={handlePlay}
                                    onEnablePIP={handleEnablePIP}
                                    onDisablePIP={handleDisablePIP}
                                    onPause={onPause}
                                    onBuffer={() => console.log('onBuffer')}
                                    onSeek={(e) => console.log('onSeek', e)}
                                    onEnded={handleEnded}
                                    onError={(e) => console.log('onError', e)}
                                    onProgress={handleProgress}
                                    onDuration={handleDuration}
                                    config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                                />
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
            <CheckpointModal reset={reset} resume={resume} setAudioTimestamp={setAudioTimestamp} checkpointShow={checkpointShow} setCheckpointShow={setCheckpointShow} />
        </Container>
    )
}

export default Step
