import React from 'react'
import { Route, Redirect } from 'react-router-dom'

// handle the private routes
function PrivateRoute({ component: Component, isAuthenticated, ...rest }) {
    console.log('rest.isAuthenticated in <PrivateRoute/> is:', isAuthenticated)
    return <Route {...rest} render={(props) => (isAuthenticated ? <Component {...props} /> : <Redirect to={{ pathname: '/', state: { from: props.location } }} />)} />
}

export default PrivateRoute
