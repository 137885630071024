import React, { useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col, Button, Modal, Form, Alert } from 'react-bootstrap'

import { submitForgotPasswordForm } from '../../../core/actions/forgotPasswordActions'

import './forgotPasswordModal.scss'

function ForgotPasswordModal(props) {
    const [message, setMessage] = useState('')
    const [emailDisabled, setEmailDisabled] = useState(false)
    const [hideElement, setHideElement] = useState(false)
    const authObj = useSelector((state) => state.authReducer)

    const { isAuthenticated } = authObj

    const { register, handleSubmit, reset, errors } = useForm()

    const onSubmit = async (userData) => {
        setMessage('')
        const result = await submitForgotPasswordForm(userData)

        reset()
        setEmailDisabled(true)
        setHideElement(true)
        setMessage('An email with password reset instructions has been sent to your email address, if it exists on our system.')
    }

    return (
        <>
            {/* <Button onClick={() => setSmShow(true)}>Log In modal</Button> */}
            <Modal className="forgotpassword-modal" size="md" show={props.forgotPasswordShow} onHide={() => props.setForgotPasswordShow(false)} aria-labelledby="signup-modal">
                <Modal.Header closeButton>
                    <Modal.Title id="signup-modal">Reset your password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <Row>
                        <Col sm={{ span: 10, offset: 1 }}>
                            <h3>Reset your password</h3>
                        </Col>
                    </Row> */}
                    {message && (
                        <Row>
                            <Col sm={{ span: 10, offset: 1 }}>
                                <Alert variant="warning" style={{ marginBottom: 40 }}>
                                    {message}
                                </Alert>
                            </Col>
                        </Row>
                    )}
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group as={Row} controlId="forgotPasswordEmail" className={hideElement && 'displayNone'}>
                            <Col sm={{ span: 10, offset: 1 }}>
                                <Form.Label>
                                    <p>Please enter the email address associated with your account. We will send you a link to reset your password.</p>
                                </Form.Label>
                                <Form.Control
                                    type="email"
                                    name="emailAddress"
                                    size="sm"
                                    placeholder="example@email.com"
                                    maxLength={30}
                                    ref={register({
                                        required: true,
                                        pattern: /^\S+@\S+$/i,
                                    })}
                                    autoFocus
                                    disabled={emailDisabled}
                                />
                                {errors.emailAddress?.type === 'required' && <Form.Text className="text-error">This field is required</Form.Text>}
                                {errors.emailAddress?.type === 'pattern' && <Form.Text className="text-error">Invalid email</Form.Text>}
                            </Col>
                        </Form.Group>

                        <Row className={hideElement ? 'displayNone' : ''}>
                            <Col className="text-center">
                                <Button variant="primary" className="large-action-btn" type="submit">
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="mt-50 text-center">
                                    <span>
                                        Back to{' '}
                                        <Button
                                            variant="link"
                                            onClick={() => {
                                                props.setSignInShow(true)
                                                props.setForgotPasswordShow(false)
                                            }}>
                                            <b>Sign In</b>
                                        </Button>
                                    </span>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ForgotPasswordModal
