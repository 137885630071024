import axios from 'axios'

// const API_URL = 'http://localhost:4000'

// get list of the users
export const getUserListService = async () => {
    try {
        return await axios.get(`/api/users/getList`)
    } catch (err) {
        return {
            error: true,
            response: err.response,
        }
    }
}

// firstName, LastName, email, password
export const userSignupService = async (userData) => {
    try {
        return await axios.post(`/api/users/signup`, userData)
    } catch (err) {
        return {
            error: true,
            response: err.response,
        }
    }
}

export const getUserService = async () => {
    try {
        return await axios.get(`/api/users/getUser`)
    } catch (err) {
        return { error: true, response: err.response }
    }
}

export const updateUserService = async (userData) => {
    try {
        return await axios.post(`/api/users/updateUser`, userData)
    } catch (err) {
        return { error: true, response: err.response }
    }
}

export const updateUserPassword = async (userData) => {
    try {
        return await axios.post(`/api/users/changepassword`, userData)
    } catch (err) {
        return { error: true, response: err.response }
    }
}
